import type { IconName } from '@st/ui/components/StIcon/types'
import { delay } from '@st/utils'

export function useLanguageSelect() {
  interface LanguageMap {
    id: string
    value: string
    icon: IconName
  }
  interface Lang {
    code: string
    name: string
  }
  const { locale, locales, setLocale } = useI18n()

  const selectedLanguage = computed({
    get: () => locale.value,
    set: async (val) => setLocale(val),
  })

  watch(
    () => selectedLanguage.value,
    async (newLocale, oldLocale) => {
      if (newLocale !== oldLocale) {
        await delay(300)
        window?.location.reload()
      }
    },
  )

  function getIconId(code: string) {
    const codeToIconMap: Record<string, IconName> = {
      ru: 'f-rus',
      en: 'f-gbr',
      es: 'f-esp',
      pt: 'f-prt',
    }
    return codeToIconMap[code]
  }

  const options = computed((): LanguageMap[] =>
    (locales.value as Lang[]).map((lang: Lang) => ({
      id: lang.code,
      value: lang.name,
      icon: getIconId(lang.code),
    })),
  )

  return {
    selectedLanguage,
    options,
  }
}
